import React from 'react'
import { useDispatch } from 'react-redux'

import { splitSearchQuery } from 'packages/utils/misc'

import {
  AuthOverrideParams,
  getRefreshTokenRequest,
  getTokenRequest,
  redirectForAuthentication,
} from '../utils'
import { CACHE_KEY_CODE_VERIFIER } from '../utils/generateCodeChallenge'

type Tokens = {
  accessToken: string | undefined
  idToken: string | undefined
  impersonationToken: string | undefined
  refreshToken: string | undefined
}

export type AuthCodeWithPkceProps = {
  authClientId: string
  authToken: string | undefined
  authUrl: string
  isImpersonated?: boolean
  needsFullAuth: boolean
  needsSilentRefresh: boolean
  onAuthInitialized: (initialUrl?: string) => void
  overrideParams?: AuthOverrideParams
  refreshToken: string | undefined
  setNeedsFullAuthRedirect: (value: boolean) => void
  setTokens: (tokens: Tokens) => void
  tokenUrl: string
}

export const AuthCodeWithPkce: React.FC<AuthCodeWithPkceProps> = React.memo(
  ({
    authClientId,
    authToken,
    authUrl,
    isImpersonated = false,
    needsFullAuth,
    needsSilentRefresh,
    onAuthInitialized,
    overrideParams = {},
    refreshToken,
    setNeedsFullAuthRedirect,
    setTokens,
    tokenUrl,
  }) => {
    const dispatch = useDispatch()

    const [code, setCode] = React.useState('')

    /**
     * Handles the initial authentication request.
     * - If we already have a "code" from the server, it will be saved locally so we can exchange it for a token.
     * - Otherwise, a full auth redirect is triggered
     */
    React.useEffect(() => {
      if (!navigator.onLine) {
        setTokens({
          accessToken: undefined,
          idToken: undefined,
          impersonationToken: undefined,
          refreshToken: undefined,
        })

        onAuthInitialized()
        return
      }

      if (needsFullAuth) {
        const params = splitSearchQuery(window.location.search)
        const { code } = params

        if (code) {
          setCode(code)
        } else {
          redirectForAuthentication(authUrl, authClientId, overrideParams)
        }
      }
    }, [
      authClientId,
      authUrl,
      needsFullAuth,
      onAuthInitialized,
      overrideParams,
      setTokens,
    ])

    /**
     * Handles the "code-to-token exchange" flow.
     * - When we have a "code" from the auth server, will attempt to exchange that code for an auth token
     * - If any errors occur, we will simply trigger a full auth redirect
     */
    React.useEffect(() => {
      const getToken = async () => {
        try {
          const request = getTokenRequest(code, authClientId)
          const res = await fetch(tokenUrl, request)
          const data = await res.json()
          const {
            access_token: accessToken,
            id_token: idToken,
            impersonation_token: impersonationToken,
            refresh_token: refreshToken,
          } = data

          dispatch(
            setTokens({
              accessToken,
              idToken,
              impersonationToken,
              refreshToken,
            }),
          )

          onAuthInitialized()
        } catch (err) {
          // manually setting our token to an impersonated user's token will attempt to trigger this,
          // so we need to manually stop it when this is present
          if (!isImpersonated) {
            dispatch(setNeedsFullAuthRedirect(true))
          }
        } finally {
          sessionStorage.removeItem(CACHE_KEY_CODE_VERIFIER)
        }
      }

      if (code && !authToken) {
        getToken()
      }
    }, [
      authClientId,
      authToken,
      code,
      dispatch,
      isImpersonated,
      onAuthInitialized,
      setNeedsFullAuthRedirect,
      setTokens,
      tokenUrl,
    ])

    /**
     * Handles the "refresh token" flow.
     * - When 'needsSilentRefresh' is true, will attempt to request a new token using our existing refresh token
     * - If any errors occur, we will simply trigger a full auth redirect
     */
    React.useEffect(() => {
      const refreshAuthToken = async () => {
        try {
          if (!refreshToken) {
            throw Error(
              'No refresh token found; full authentication redirect required.',
            )
          }

          const request = getRefreshTokenRequest(refreshToken, authClientId)
          const res = await fetch(tokenUrl, request)
          const data = await res.json()
          const {
            access_token: accessToken,
            id_token: idToken,
            impersonation_token: impersonationToken,
            refresh_token: newRefreshToken,
          } = data

          if (idToken && newRefreshToken) {
            dispatch(
              setTokens({
                accessToken,
                idToken,
                impersonationToken,
                refreshToken: newRefreshToken,
              }),
            )
          } else {
            throw Error(
              'No valid tokens found; full authentication redirect required.',
            )
          }
        } catch (err) {
          dispatch(setNeedsFullAuthRedirect(true))
        }
      }

      if (needsSilentRefresh) {
        refreshAuthToken()
      }
    }, [
      authClientId,
      code,
      dispatch,
      needsSilentRefresh,
      refreshToken,
      setNeedsFullAuthRedirect,
      setTokens,
      tokenUrl,
    ])

    return null
  },
)
