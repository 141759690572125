import React from 'react'
import { createRoot } from 'react-dom/client'

import {
  initializeSentry,
  SentryContextProvider,
  SentryErrorBoundary,
} from 'packages/common/src/Sentry'
import { Iconic } from 'packages/iconic'
import { CssReset } from 'packages/styles'

import 'react-toastify/dist/ReactToastify.css'

// import reportWebVitals from '../../reportWebVitals'
import HkHub from './HkHub'
import { initializeDatadogRum } from './initializeDatadogRum'

const sentryIsActive = initializeSentry()
initializeDatadogRum()

const container = document.getElementById('root')

if (container) {
  const root = createRoot(container)

  root.render(
    <React.StrictMode>
      <SentryContextProvider value={{ sentryIsActive }}>
        <SentryErrorBoundary boundary={'global'}>
          <Iconic />
          <CssReset />
          <HkHub />
        </SentryErrorBoundary>
      </SentryContextProvider>
    </React.StrictMode>,
  )
} else {
  throw new Error('Root container not found')
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
