import get from 'lodash/get' // eslint-disable-line

import { createSelector } from 'reselect'

import { transformNormalizedToTyped } from 'packages/utils/store/store.utils'

import {
  HousekeepersState,
  Housekeeper,
  HousekeeperAttributesNames,
} from '../housekeepers.types'
import { getHkTierById } from './getHkTierById'
import { getHkUserById } from './getHkUserById'

type PickedHkProps = Pick<Housekeeper, 'employeeType' | 'id'>

const getHousekeepersState = (state: HousekeepersState) => state
const getHousekeeperId = (_, id?: string) => id

/**
 * Returns a housekeeper embedding tier, and user information
 *
 * @param state
 * @param id the housekeeper id
 */
export const getHousekeeperById = createSelector(
  // Input selectors
  getHousekeepersState,
  getHousekeeperId,

  // Output selector
  (state, id): Housekeeper | undefined => {
    if (!id) return undefined

    const rawHousekeeper = get(state.data, id)
    if (!rawHousekeeper) {
      return undefined
    }

    // build & populate all of the relationships data
    const borrowedZones = get(
      rawHousekeeper,
      'relationships.borrowedZones.data',
      [],
    ).map(bz => bz.id)

    const tier = getHkTierById(
      state,
      rawHousekeeper.relationships.tier?.data?.id,
    )
    const user = getHkUserById(state, rawHousekeeper.relationships.user.data.id)
    //TODO: Clean this up
    const zone = get(
      rawHousekeeper,
      'relationships.zone.data',
      undefined,
    ) as any //eslint-disable-line

    // NOTE: In spite of how this looks, this only actually returns 'id' and 'employeeType' fields
    // TS used to let us get away with this, but after upgrading past 3.9, it would not; hence the custom type
    const partialTransformedHk = transformNormalizedToTyped<PickedHkProps>(
      rawHousekeeper,
      HousekeeperAttributesNames,
    )

    return {
      ...partialTransformedHk,
      borrowedZones,
      tier,
      user,
      zone,
    }
  },
)
