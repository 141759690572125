import styled from '@emotion/styled'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Loader } from 'packages/common'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors, layers } from 'packages/styles'

import { clearImpersonationToken } from 'app/hkhub/store/auth/actions/clearImpersonationToken'
import { fetchImpersonationToken } from 'app/hkhub/store/auth/actions/fetchImpersonationToken'
import { getImpersonationToken } from 'app/hkhub/store/auth/selectors/getImpersonationToken'
import { User } from 'app/hkhub/store/users'
import { getAuthUser } from 'app/hkhub/store/users/selectors/getAuthUser'

import AsyncUserSelector from './AsyncUserSelector'

const St = {
  CloseIcon: styled(SvgIcon)`
    color: ${colors.midnight80};
  `,
  ImpersonationPanel: styled.div`
    align-items: center;
    background: ${colors.midnight4};
    border: 1px solid ${colors.midnight20};
    display: flex;
    gap: 8px;
    padding: 8px;
    position: relative;
  `,
  UserName: styled.div`
    color: ${colors.midnight50};
    text-align: right;
    width: 100%;
  `,
  UserSelector: styled(AsyncUserSelector)`
    background: ${colors.midnight2};
    border: 1px solid ${colors.midnight20};
    border-radius: 4px;
    width: 100%;
    z-index: ${layers.zoneSelector};
  `,
}

export enum ImpersonationPanelTestIds {
  container = 'ImpersonationPanel__container',
}

export const ImpersonationPanel: React.FC = React.memo(() => {
  const dispatch = useDispatch()
  const authUser = useSelector(getAuthUser)
  const [isLoading, setIsLoading] = React.useState(false)
  const isImpersonating = useSelector(getImpersonationToken)
  const handleUserSelectionChange = React.useCallback(
    async (user: User) => {
      setIsLoading(true)
      await dispatch(fetchImpersonationToken(user))
    },
    [dispatch],
  )

  const handleCloseClick = React.useCallback(() => {
    setIsLoading(true)
    dispatch(clearImpersonationToken())
  }, [dispatch])

  if (!isImpersonating && !authUser?.isSuperuser) return null

  return (
    <>
      <St.ImpersonationPanel data-testid={ImpersonationPanelTestIds.container}>
        {isImpersonating && (
          <St.CloseIcon
            icon={IconName.x}
            size={24}
            onClick={handleCloseClick}
          />
        )}
        <St.UserSelector onUserSelectionChange={handleUserSelectionChange} />
      </St.ImpersonationPanel>
      {isLoading && <Loader size={14} />}
    </>
  )
})
